@import "../App.scss";

/* Scroll down section */
.scroll-container {
  display: none;
  align-items: center;
  $size: 20px;
  margin: {
    top: $padding--big;
    bottom: $size;
  }
}

.section {
  &__image--scroll {
    width: 27px;
    margin-right: $padding--normal;
  }

  &__subtitle {
    color: $clr--dark-gray;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__subtitle--scroll {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .scroll-container {
    display: flex;
  }
}

/* Info and description blocks */

.info {
  margin: {
    right: $margin--small;
    left: $margin--small;
    bottom: 50px;
  };
  padding-top: $padding--small;

  p {
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
  }
}

@media screen and (min-width: 768px) {
  .info {
    margin: {
      right: $margin--normal;
      left: $margin--normal;
    };

    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .info {
    margin: {
      left: $margin--big;
      right: $margin--extrabig;
    };
  }
}