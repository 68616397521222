@import '../App';

.page {
  margin: {
    //top: $padding--normal;
    left: $margin--small;
    right: $margin--small;
  };
}

.section {
  &__title--page {
    opacity: 0.3;
    color: $clr--medium-gray;
    font-size: $fs--h1;
    font-weight: $fw--bold;
    letter-spacing: 0;
    line-height: 44px;
  }

  &__text {
    color: $clr--dark-gray;
    padding-top: $padding--normal;

    p {
      color: inherit;
      font-size: $fs--small;
      letter-spacing: 0;
      line-height: 24px;
    }

    h2 {
      color: inherit;
      font-size: $fs--h2;
      font-weight: $fw--bold;
      letter-spacing: 0;
      line-height: 38px;
    }

    ul, ol {
      list-style-position: inside;
      word-break: break-word;
      margin-bottom: 30px;
      line-height: 24px;
    }

    ul {
      text-align: left;
      list-style-type: disc;

      ul {
        margin-left: 20px;
      }
    }

    ol {
      text-align: justify;
    }

    h4 {
      margin-bottom: 16px;
    }
  }
}

@media screen and (min-width: 768px) {
  .page {
    margin: {
      left: 0;
      right: 0;
    }
  }

  .section {
    &__title--page {
      font-size: 80px;
      line-height: 70px;
      margin: {
        left: $margin--normal;
        right: $margin--normal;
        bottom: 35px;
      }
    }

    &__text {
      margin: {
        left: $margin--big;
        right: $margin--extrabig;
      };
      padding-top: 0;
    }
  }
}

@media screen and (min-width: 1920px) {
  .section__title--page {
    margin: {
      left: $margin--medium;
      right: $margin--medium;
    };
  }
}