@import "../../App.scss";

.header {
  display: flex;
  flex-direction: column;

  &--active {
    background-color: $clr--white;

    & .nav__list {
      background-color: $clr--white;
    }
  }

  &--nav-open {
    position: fixed;
    z-index: 1000;
    background-color: $clr--light-gray;
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }
}

.nav {
  padding: {
    left: $padding--small;
    right: $padding--small;
    bottom: $padding--normal;
    top: $padding--small;
  };

  &__list {
    background: $clr--light-gray;
    z-index: 100;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__item {
    width: 100%;
    order: 2;
    text-align: center;
    display: none;
    padding: {
      top: $padding--very-small;
      bottom: $padding--very-small;
    };
    font-size: $fs--middle;
    letter-spacing: 0;
    line-height: 28px;
  }

  &__item--active {
    display: block;
  }

  &__link {
    color: $clr--purple;
    text-decoration: none;
  }

  &__link--sticker {
    position: relative;
  }

  &__button {
    order: 3;
    display: none;
    cursor: pointer;
  }

  &__lang-change {
    width: auto;
    color: $clr--dark-gray;
    opacity: 0.5;
    margin-left: calc(50% - 43px);
    padding-right: 7px;
    cursor: pointer;
  }

  &__lang-change--active {
    color: $clr--purple;
    font-weight: 600;
    opacity: 1.0;
  }

  &__lang-change + &__lang-change {
    margin: {
      right: calc(50% - 43px);
      left: 0;
    }
    padding: {
      left: 7px;
      right: 0;
    }
  }
}

.logo {
  width: 130px;
  order: 1;
  display: inline-block;
  padding: 0;
}

.toggle {
  width: 20px;
  font-size: 20px;
  order: 1;
  display: inline-block;
  padding: 0;

  &--dropdown path {
    fill: $clr--blue;
  }
}

.sticker--new {
  background-color: $clr--purple;
  color: $clr--white;
  font-size: 10px;
  padding: 4px;
  border-radius: 6px;
  position: absolute;
  top: -8px;
  line-height: 10px;
}

@media screen and (min-width: 768px) {
  .nav {
    padding: {
      left: $margin--normal;
      right: $margin--normal;
      top: $padding--normal;
    };
  }
}

@media screen and (min-width: 1024px) {
  .header--active {
    background-color: $clr--light-gray;

    .nav__list {
      background-color: $clr--light-gray;
    }
  }

  .nav {
    bottom: 45px;

    &__list {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &__item {
      display: inline-block;
      width: auto;
      font-size: $fs--small;

      padding: {
        left: clamp(5px, 20px, 30px);
        right: clamp(5px, 20px, 30px);
      };
    }

    &__button {
      order: 3;
      box-sizing: border-box;
      height: 32px;
      min-width: 135px;
      border: 1px solid $clr--purple;
      border-radius: 21px;
      padding: 0;
      color: $clr--purple;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
      margin-left: min(20px, 40px);
    }

    &__lang-change, &__lang-change + &__lang-change {
      margin: 0;
      width: auto;
      padding-right: 8px;
    }

    &__link--sticker {
      white-space: nowrap;
    }
  }

  .logo {
    width: 163px;
    min-width: 163px;
    margin-right: auto;
    padding: 0 !important;
    white-space: normal;
  }

  .toggle {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .nav__item {
    padding: {
      left: clamp(20px, 30px, 40px);
      right: clamp(20px, 30px, 40px);
    };
  }
}

@media screen and (min-width: 1920px) {
  .nav {
    padding: {
      left: $margin--medium;
      right: $margin--medium;
    };
  }
}