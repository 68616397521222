@import "../App.scss";

.translation-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Language and style selectors */

.translation-choices {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choice-container {
  color: $clr--blue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 calc(50% - 32px);
}

.switch-button {
  border-radius: 100%;
  background-color: $clr--blue;
  padding: 6px;
  margin: {
    bottom: $margin--small;
    left: 10px;
    right: 10px;
  }
  cursor: pointer;

  &:hover {
    background-color: $clr--blue--hover;
  }

  path {
    fill: $clr--white;
  }

  &__overlay {
    position: absolute;
    left: calc(50% - 18px);
    top: 50%;
    margin: {
      left: 0;
      right: 0;
    }
  }
}

.dropdown-container {
  display: flex;

  &__box {
    border-width: 2px;
    border-style: solid;
    border-color: $clr--blue;
    border-radius: 18px;
    cursor: pointer;
    margin-bottom: $margin--small;
    padding: 10px 20px 10px 20px
  }

  &__text {
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
}

.dropdown {
  position: fixed;
  background: #FFFFFF;
  left: 0;
  width: 100%;
  //height: 0;
  transition: all .5s ease-in-out;
  display: none;
  overflow: hidden;
  opacity: 0;

  &--active {
    //height: 100%;
    z-index: 1000;
    opacity: 1;
    display: block;
    overflow-y: scroll;
    top: 75px;
    bottom: 0;
  }

  &__list {
    list-style-type: none;
  }

  &__item {
    cursor: pointer;
  }

  &__item, &__divider {
    color: $clr--blue;
    font-size: $fs--middle;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin: {
      bottom: $padding--big;
    };
  }

  p {
    opacity: 0.5;
    color: #282828;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    text-transform: lowercase;
  }

  p:first-letter {
    text-transform: capitalize;
  }

  &--language {
    background-color: $clr--white;

    &__control {  //dropdown-control--language
      background-color: inherit;
      border: 0;
      padding: {
        left: $padding--very-small;
        right: 0;
      };
      border-radius: 21px;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }

    &__menu { //dropdown-menu--language
      max-height: 265px;
      border-radius: 15px;
      background-color: $clr--white;
      box-shadow: 0 2px 4px 0 rgba(40,40,40,.26);
      margin-top: 0px;
      margin-bottom: 0px;

      &-list {
        border-radius: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: inherit !important;
      }

      #dropdown-divider {
        pointer-events: none;
      }
    }
    /*
    ::-webkit-scrollbar {
      display: none;
    }*/

    &__option {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
  
      &:hover {
        background-color: $clr--light-gray;
      }
      &.is-selected {
        background-color: $clr--light-gray;
      }
    }
  }
}

.toggle--dropdown {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .translation-choices {
    display: flex;
    align-items: center;
    margin-bottom: $margin--small;
  }

  .choice-container {
    flex: 0 0 calc(50% - 21px);
    justify-content: flex-start;
  }

  .switch-button {
    padding: 11px;
    border-radius: 20px;
    margin: {
      right: 8px;
      bottom: 0;
      left: 0;
    }
  }

  .dropdown {
    position: relative;
    display: flex;
    opacity: 1;
    overflow: unset;
    background-color: initial;

    &:hover {
      cursor: pointer;
      background-color: $clr--blue--hover;
    }

    &-container {
      height: 42px;
      //width: 247px;
      border-radius: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 60%;
      max-width: 230px;
      font-weight: 500;
      background-color: $clr--blue;

      &:hover {
        background-color: $clr--blue--hover;
      }

      p {
        font-size: $fs--small;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        font-weight: inherit;
        color: $clr--white;
        min-width: auto;
        word-break: normal;
        //flex: 1 0 35%;
        margin: 0;
        white-space: nowrap;
        padding: {
          left: 16px;
          right: 8px;
        }
      }
  
      &--style {
        flex: 0 0 45%;
        padding-left: 10px;
        justify-content: right;
  
        label {
          color: $clr--dark-gray;
          font-size: $fs--small;
          font-weight: $fw--bold;
          letter-spacing: 0;
          line-height: 19px;
          text-align: right;
          margin-right: 11px;
          flex-basis: 20%;
        }
      }
    }

    &--language {
      display: block;
      height: 100%;
      //flex: 0 0 55%;
      background-color: inherit;
      border-radius: 21px;
      transition: none;

      &__control {  //-control--language
        transition: none;
        cursor: inherit;
        //padding-left: 8px;
        padding-left: 0;
        white-space: nowrap;
      }

      &__value-container {
        padding-left: 8px;
        color: $clr--white;
      }

      &__indicators {
        width: 32px;
        border-color: $clr--white transparent transparent;

        /*
        &--closed {
          border-color: $clr--white transparent transparent;
        }
    
        &--open {
          border-color: transparent transparent $clr--white !important;
        }
        */
      }
    }

    &--style {
      height: 42px;
      width: 122px;
      border-radius: 21px;
      transition: none;
    }

    &--auto {
      background-color: rgba(155, 155, 155, 0.5);
      .toggleOption.selected svg {
        fill: $clr--blue;
      }
    }

    &--yellow {
      background-color: $clr--yellow;
      .toggleOption.selected svg {
        fill: $clr--yellow;
      }
    }

    &--purple {
      background-color: $clr--purple;
      .toggleOption.selected svg {
        fill: $clr--purple;
      }
    }
  }

  .Dropdown-placeholder {
    color: $clr--white;
    margin-right: 10px;
  }

  .pipe {
    box-sizing: border-box;
    height: 42px;
    width: 1px;
    border: 1px solid #f4f4f4;
    opacity: .3;
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  .toggleOption {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 100%;
    }

    &.selected {
      background-color: $clr--white;
      border-radius: 100%;

      svg {
        opacity: 1;
      }
    }

    div {
      width: 22px;
    }

    svg {
      display: block;
      margin: auto;
      opacity: 0.6;
      fill: $clr--white;
    }
  }
}

/* Translation text input and output boxes */

.translation-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.box {
  position: relative;
  flex: 1 1 50%;
  display: flex;
  margin-bottom: 8px;
  padding: {
    left: 2px;
    right: 2px;
  };

  &__character-count, &__clear-button, &__output-copy, &__edit-button, &__accept-button, &__cancel-button {
    position: absolute;
    opacity: 0.5;
    color: #666666;
  }

  &__clear-button, &__input-copy, &__output-copy, &__edit-button, &__accept-button, &__cancel-button {
    cursor: pointer;
    font-size: 20px;
  }

  &__clear-button:hover, &__input-copy:hover, &__output-copy:hover, &__edit-button:hover, &__accept-button:hover, &__cancel-button:hover {
    color: #474747;
  }

  &__clear-button {
    top: 10px;
    right: 12px;
  }


  &__character-count {
    bottom: 10px;
    right: 12px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  &__input-copy {
    margin-left: 8px;
  }

  &__output-copy {
    bottom: 10px;
    right: 12px;
  }

  &__container-editing-buttons, &__container-edit-button { 
    top: 10px;
    right: 12px;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
  }

  &__accept-button, &__cancel-button{
    position: static;
    padding-left: 5px;
  }

  &__edit-button {
    position: static;
  }
  
  ::-webkit-scrollbar{
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece{
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb{
    background-color: #CBCBCB;
    //outline: 2px solid #FFF;
    //outline-offset: -2px;
    //border: .1px solid #B7B7B7;
    outline: none;
    border: 0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover{
    background-color: #909090;
  }
}

.div__textarea {
  width: 100%;
}

textarea {
  font-family: $font-family;
  font-size: $fs--small;
  line-height: 24px;
  resize: none;
  border: none;
  border-radius: 10px;
  padding: {
    top: 14px;
    bottom: 14px;
    left: $padding--small;
    right: $padding--small;
  };

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(77, 182, 172, 0.4);
  }

  &::placeholder {
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &::-webkit-input-placeholder {
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &:-moz-placeholder { /* Firefox 18- */
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &:-ms-input-placeholder {
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }
}

#output-textarea {
  padding: {
    right: 54px;
  };
}

@media screen and (min-width: 1024px) {
  .translation-box {
    //grid-column-gap: 15px;
    flex-wrap: wrap;
  }

  .box {
    flex-basis: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    width: calc(50% - 10px);
    align-self: flex-end;

    &--input {
      padding-left: 2px;
      align-self: flex-start;
    }

    &__clear-button, &__input-copy, &__output-copy, &__edit-button, &__accept-button, &__cancel-button {
      font-size: 24px;
    }

    ::-webkit-scrollbar{
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track-piece{
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb{
      background-color: #CBCBCB;
      //outline: 2px solid #FFF;
      //outline-offset: -2px;
      //border: .1px solid #B7B7B7;
      outline: none;
      border: 0;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover{
      background-color: #909090;
    }
  }

  /*.box + .box, .comparison-container {
    margin-left: 15px;
  }*/

  textarea {
    font-size: $fs--h2;
    line-height: 35px;
    letter-spacing: 0;
    padding: 24px;
    //direction: ltr;

    &::placeholder {
      font-size: $fs--h2;
      line-height: 35px;
    }

    &::-webkit-input-placeholder {
      font-size: $fs--h2;
      line-height: 35px;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: $fs--h2;
      line-height: 35px;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      font-size: $fs--h2;
      line-height: 35px;
    }

    &:-ms-input-placeholder {
      font-size: $fs--h2;
      line-height: 35px;
    }
  }

  #output-textarea {
    padding: {
      right: 58px;
    };
  }

}

/* Comparisons */
.comparison-container {
  //grid-area: c;
  //grid-column: 2;
  //width: 100%;
  box-sizing: border-box;

  &--active {
    flex-basis: 33%;
    overflow: hidden;

    .section__button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  ::-webkit-scrollbar{
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece{
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb{
    background-color: #CBCBCB;
    //outline: 2px solid #FFF;
    //outline-offset: -2px;
    //border: .1px solid #B7B7B7;
    outline: none;
    border: 0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover{
    background-color: #909090;
  }
}

.section__button {
  height: 42px;
  width: calc(100% - 4px);
  border-radius: 21px;
  background-color: rgba(155, 155, 155, 0.1);
  color: $clr--blue;
  font-size: $fs--small;
  font-weight: $fw--bold;
  letter-spacing: 0;
  border: 0;
  padding-left: 30px;
  display: block;
  margin: 2px auto 2px auto;

  &:hover {
    background-color: rgba(155, 155, 155, 0.2);
    cursor: pointer;
  }

  &:focus {
    outline: 2px solid rgba(77, 182, 172, 0.4);
    //box-shadow: 0 0 0 2px rgba(77, 182, 172, 0.4);
  }

  svg {
    float: right;
    margin-right: 24px;
  }
}

.comparison-box {
  background-color: $clr--white;
  height: calc(100% - 42px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;

  span {
    display: flex;
    margin-bottom: $padding--very-small;
  }

  p {
    margin: 0;
  }

  .div__paragraph {
    font-weight: $fw--bold;
    color: $clr--blue;
    margin-right: 10px;
    flex: 0 0 75px;
  }
}

@media screen and (min-width: 1024px) {
  .section__button {
    margin-bottom: 0;
  }

  .comparison-container {
    margin-top: 10px;
    width: calc(50% - 10px);
    align-self: flex-end;

    &--active {
      width: calc(50% - 10px);
    }
  }
}

.hint {
  display: flex;
  font-family: $font-family;
  position: absolute;
  padding: 24px;
  padding-left: 30px;
  border-radius: 10px;
  border-top-right-radius: 0;
  background-color: #fff;
  box-shadow: 10px 0px 30px 0 rgba(155, 155, 155, 0.4);
  white-space: pre-wrap;
  user-select: none;
  animation: fade 10s forwards;
  &__close-button {
    position: absolute;
    padding-right: 20px;
    padding-bottom: 20px;
    top: 7px;
    left: 7px;
    opacity: 0.5;
    color: #666666;
    -webkit-tap-highlight-color: transparent;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}